<template>
  <v-container>
    <v-row justify="center">
      <v-img
        :src="imagem_src"
        v-if="imagem_src"
        max-width="250"
        contains
        style="cursor: pointer"
        @click="cropper = true"
      ></v-img>
      <v-icon
        v-else
        size="250"
        @click="$refs.imageInput.click()"
        style="cursor: pointer"
        >mdi-face</v-icon
      >
    </v-row>
    <v-row justify="center">
      <v-col sm="10" md="5">
        <v-btn
          outlined
          block
          color="grey darken-2"
          @click="$refs.imageInput.click()"
        >
          <v-spacer></v-spacer>
          CARREGAR IMAGEM
          <v-spacer></v-spacer>
          <v-icon>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <input
      type="file"
      style="display:none"
      accept="image/*"
      ref="imageInput"
      @input="carregarImagem"
    />
    <v-overlay v-model="cropper" :opacity="0.2">
      <cropper
        :src="cropper_src"
        @cropped="croppedImage"
        @cancelar="cancelarCropper"
      ></cropper>
    </v-overlay>
  </v-container>
</template>

<script>
const Cropper = () => import("../Cropper");

export default {
  components: {
    Cropper
  },
  data() {
    return {
      imagem_src: null,
      cropper_src: null,
      cropper: false
    };
  },
  mounted() {
    this.$root.$on("reset_fields", () => {
      this.cropper = false;
      this.cropper_src = null;
      this.imagem_src = null;
    });
  },
  methods: {
    cancelarCropper() {
      this.cropper = false;
    },
    carregarImagem(event) {
      let imagem = event.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.cropper_src = reader.result;
        this.cropper = true;
      };
      if (imagem) {
        reader.readAsDataURL(imagem);
      }
    },
    croppedImage(image) {
      this.imagem_src = image;
      this.$emit("confirmar", image);
      this.cropper = false;
    }
  }
};
</script>
